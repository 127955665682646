import React, { useState } from "react";
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import { Link } from 'react-router-dom';
import logooriginal from './image/Logo-min.webp';
import Footer from './components/Footer';

function Admin() {
  const [activeTab, setActiveTab] = useState('accueil'); 

  const handleNavLinkClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  }

return (
        <div className="container">
              
        <header>
        <img src={lighthouse} alt="Performance" className='performance' />
        <img src={lighthouseMobile} alt="Performance" className='performance1' />
        <Link to='/' className='link'>
          <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
          <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
          <h2>Hello et Bienvenue !</h2>
          </Link>
        
    
          <nav>
      <a href="#accueil" onClick={(e) => handleNavLinkClick(e, 'accueil')}>Accueil</a>
      <a href="#travaux" onClick={(e) => handleNavLinkClick(e, 'travaux')}>Mes travaux</a>
      <a href="/login">Se Connecter</a>
        <a href="#blog" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
        </header>
    
     
     <main>
     </main>
     <Footer />
</div>
     
 );
}

export default Admin;