import React, { useState } from "react";
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import { Link, useNavigate } from 'react-router-dom';
import logooriginal from './image/Logo-min.webp';
import Footer from './components/Footer';
import './Log.css';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('accueil'); 

  const handleSubmit = (event) => {
    event.preventDefault();
    fetch('/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then(data => {
      localStorage.setItem('authToken', data.token);
        
        navigate('/client');
        })
    .catch(error => {
      console.error('Il y a eu un problème avec la requête fetch:', error);
      // Ici, vous pouvez gérer l'affichage d'une erreur pour l'utilisateur
    });
};

const handleNavLinkClick = (e, tabId) => {
  e.preventDefault();
  setActiveTab(tabId);
}

return (
        <div className="container">
          
          <header>
          <img src={lighthouse} alt="Performance" className='performance' />
          <img src={lighthouseMobile} alt="Performance" className='performance1' />
          <Link to='/' className='link'>
            <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
            <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
            <h2>Hello et Bienvenue !</h2>
            </Link>
          
    
            <nav>
            <Link to='/' className='link'>Accueil</Link>
            <Link to='/' className='link'>Mes travaux</Link>
      <a href="/login">Se Connecter</a>
        <a href="/" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
          </header>
    
       
       <main>
       <h1>Connexion</h1>
       <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email:</label>
          <input 
            type="email" 
            id="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />

          <label htmlFor="password">Password:</label>
          <input 
            type="password" 
            id="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            required 
          />

          <button type="submit">Se Connecter</button>
        </form>
        <Link to='/register' className='compte'>Pas de compte ? Enregistrez-vous</Link>
        </main>
        <Footer />

</div>
     
 );
}

export default Login;