import React, { useState, useEffect } from "react";
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import { Link, useNavigate } from 'react-router-dom';
import logooriginal from './image/Logo-min.webp';
import Footer from './components/Footer';
import './Log.css';

function Client() {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');
  const [fileList, setFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');
  const [uploadMessage, setUploadMessage] = useState('');
  const [uploadFile, setUploadFile] = useState(null);
  const [activeTab, setActiveTab] = useState('accueil'); 

  // Handle file change
  const handleFileChange = (event) => {
    setUploadFile(event.target.files[0]); // Set the file to state
    setUploadMessage(''); // Reset the message
  };

  // Handle file upload
  const handleFileUpload = (event) => {
    event.preventDefault();

    if (!uploadFile) {
      setUploadMessage('Veuillez choisir un fichier !');
      return;
    }

    const formData = new FormData();
    formData.append('file', uploadFile);
    
    fetch('/upload', {
      method: 'POST',
      body: formData,
    })
    .then(response => {
      if (response.ok) {
        setUploadMessage('Fichier uploadé !');
        // Additional logic if needed
      } else {
        setUploadMessage("Erreur lors de l'upload du fichier !");
      }
    })
    .catch(error => {
      console.error(error);
      setUploadMessage("Erreur lors de l'upload du fichier !");
    });
  };


  useEffect(() => {
    fetch('/get-file-name')
      .then(response => response.json())
      .then(data => {
        setFileName(data.fileName);
      })
      .catch(error => {
        console.error(error);
      });

    fetch("/filesSiteWeb")
      .then(response => response.json())
      .then(files => {
        setFileList(files);
      })
      .catch(error => {
        console.error(error);
      });
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };

  const handleFileSelectChange = (event) => {
    setSelectedFile(event.target.value);
  };

  const handleDownloadClick = (event) => {
    if (!selectedFile) {
      event.preventDefault();
      alert("Sélectionnez un fichier à télécharger");
    }
  };

  const handleNavLinkClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  }

return ( 
<div className="container">

    <header>
      <img src={lighthouse} alt="Performance" className='performance' />
      <img src={lighthouseMobile} alt="Performance" className='performance1' />
      <Link to='/' className='link'>
        <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
        <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
        <h2>Hello et Bienvenue !</h2>
      </Link>

      <nav>
      <a href="#accueil" onClick={(e) => handleNavLinkClick(e, 'accueil')}>Accueil</a>
      <a href="#travaux" onClick={(e) => handleNavLinkClick(e, 'travaux')}>Mes travaux</a>
      <button onClick={handleLogout}>Déconnexion</button>
        <a href="#blog" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
    </header>


    <main>
      <h3 class="heading">Espace client</h3>
      <h3>Mes tarifs - rappel</h3>
      <p class="project1">Les prix s'entendent TTC pour le forfait et le tarif jour
        <ol class="project">
          <li> <span class="bold">150 € TTC par jour à la carte :</span> Le tarif est net.
          </li>
          <li> <span class="bold">Au forfait de 400 € pour 4 pages maximum :</span> Cela comprend la page d'accueil (Home) + 3 autres pages selon votre choix maximum</li>
        </ol>
      </p>
      <h5>Merci de m'envoyer votre projet au format ZIP </h5>
      
      <div id="tableau">
        <div class="upload1">
        <div id="uploaded-file" style={{ display: 'block' }}>
        <span id="file-name"> <input 
        type="file" 
        id="file" 
        onChange={handleFileChange}
      />
      <button id="submitBtn" onClick={handleFileUpload}>
        Envoyer
      </button>
      <div id="message4">{uploadMessage}</div></span>
        
      </div>
</div>
</div>
      
      <h5> Vous pouvez télécharger un exemple de cahier des charges ci-dessous</h5>
      <p class="project1">Expliquez bien votre projet détaillé en plusieurs étapes comme expliqué ci-dessous (Les fichiers acceptés sont JPG, JPEG, PNG, Word, PDF et ZIP) :<br />
        Merci de nommer votre fichier par exemple comme dans l'exemple qui suit : <span class="bold">NomProjet_Date_Envoi_<br />Nomclient_PrenomClient.pdf</span> <br />
        Vous pourrez ensuite me contacter par mail via le formulaire en bas de page ou par téléphone - Coordonnées en bas de page - pour discuter de votre projet. Et envoyer les images, le design, la maquette, le logo, etc... Veuillez noter par ailleurs que lon peut organiser une visio-conférence MEET de Google<br />
        <ol class="project">
          <li><span class="bold">Votre projet :</span> Nom, prenom, adresse, numéro de téléphone, email.<br />
            Ensuite précisez tout en détail notamment si vous voulez un site clé en main ou non, s'il faut prévoir ue formation à l'administration du site ou non etc...
          </li>
          <li><span class="bold">Votre cible :</span> public, b2b ou les deux</li>
          <li><span class="bold">Votre objectif:</span> Monétiser, site vitrine...</li>
          <li><span class="bold">Votre budget :</span> Mon tarif journalier est de 150 net ttc donc vous devez réfléchir à votre budget en fonction de ce tarif</li>
          <li><span class="bold">Votre délai :</span> Prévoir différents délai selon que vous modifiez votre projeet en cours de route</li>
          <li><span class="bold">Livraison :</span> Merci d'indiquer si vous êtes favorable à la visio-conférence sur meet par exemple, où,
            l'on peut partager l'écran ou vous verrez l'état d'avancement de votre projet </li>
          <li><span class="bold">Prise de contact :</span> Merci d'utiliser le formulaire en bas de page pour me contacter et m'informer de votre volonté de présenter le projet</li>
        </ol>
      </p>

      <select id="file-select" onChange={handleFileSelectChange}>
        {fileList.map(file => (
          <option key={file} value={file}>{file}</option>
        ))}
      </select>

      <a 
        id="download-link" 
        href={`/download/SiteWeb/${encodeURIComponent(selectedFile)}`} 
        onClick={handleDownloadClick}
      >
        Télécharger
      </a>
   
   

  </main>
  <Footer />
</div>

     
 );
}

export default Client;