import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import Error from '../components/Error';

const AuthGuard = ({ children }) => {
  const isAuthenticated = localStorage.getItem('authToken');
  const location = useLocation();

  if (!isAuthenticated) {
    // Si l'utilisateur n'est pas authentifié, redirigez-le vers la page de connexion
    return <Navigate to="/login" />;
  } else if (location.pathname === '*' || location.pathname === '/*') {
    // Si l'utilisateur est authentifié mais que la route est '*', affichez NotFound
    return <Error />;
  } else {
    // Si l'utilisateur est authentifié et que la route est valide, affichez le contenu protégé
    return children;
  }
};

export default AuthGuard;
