import React from "react";
import { Link } from 'react-router-dom';
import '../Footer.css';

function Footer () {


return (
<footer>      
<div className="footer">
<p className="legal">Informations Légales</p>
<p>© 2023 Vidal bravo - Jandia Miguel - 1, place Joseph Cassanyes 66000 Perpignan - Lundi Vendredi 9h / 18h</p>
<p className="display"><a href="tel:+33765501408" className="tel">Appeler maintenant</a> - <Link to='/contact' className="tel">Envoyez-nous un email</Link></p>
</div>
</footer>
)
}
export default Footer;