import React, { useEffect, useState } from "react";
import './styles.css';
import ShareExcellence from './image/ShareExcellence.webp';
import LogoShare from './image/LogoShare.webp';
import Nina from './image/Nina.png';
import logooriginal from './image/Logo-min.webp';
import logo2 from './image/Logo2.webp';
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import restaurant from './image/restaurant.webp';
import fashionBoutique from './image/fashionBoutique.webp';
import { Link } from 'react-router-dom';
import Carousel from './components/Carousel'
import conception from './image/conception.webp';
import training from './image/training.webp';
import support from './image/support.webp';
import Footer from './components/Footer';

function Home() {
  const [activeTab, setActiveTab] = useState('accueil'); 
  const [progressStartValue1, setProgressStartValue1] = useState(0);
  const [progressStartValue2, setProgressStartValue2] = useState(0);
  const [progressStartValue3, setProgressStartValue3] = useState(0);
  const [progressStartValue4, setProgressStartValue4] = useState(0);
  const logos = [logo2, LogoShare];
  const urls = [
    'https://vidalbravo-jandia.fr/',
    'https://www.shareexcellence.fr/',
    // ...
  ];
  

  useEffect(() => {
    const progress1 = setInterval(() => {
      setProgressStartValue1(prev => {
        if (prev >= 55) clearInterval(progress1);
        return prev + 1;
      });
    }, 100);

    const progress2 = setInterval(() => {
      setProgressStartValue2(prev => {
        if (prev >= 99) clearInterval(progress2);
        return prev + 1;
      });
    }, 100);

    const progress3 = setInterval(() => {
      setProgressStartValue3(prev => {
        if (prev >= 99) clearInterval(progress3);
        return prev + 1;
      });
    }, 100);
    const progress4 = setInterval(() => {
      setProgressStartValue4(prev => {
        if (prev >= 49) clearInterval(progress4);
        return prev + 1;
      });
    }, 100);
    // Assurez-vous de nettoyer les intervalles lors du démontage du composant
    return () => {
      clearInterval(progress1);
      clearInterval(progress2);
      clearInterval(progress3);
      clearInterval(progress4);
    };
  }, []);

  const handleNavLinkClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  }
  

  return (
    <div className="container">
      
      <header>
      <img src={lighthouse} alt="Performance" className='performance' />
      <img src={lighthouseMobile} alt="Performance" className='performance1' />
      <Link to='/' className='link'>
        <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
        <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
        <h2>Hello et Bienvenue !</h2>
        </Link>
      

      <nav>
      <a href="#accueil" onClick={(e) => handleNavLinkClick(e, 'accueil')}>Accueil</a>
      <a href="#travaux" onClick={(e) => handleNavLinkClick(e, 'travaux')}>Mes travaux</a>
      <a href="/login">Se Connecter</a>
        <a href="#blog" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
      </header>

   <main>
            {activeTab === 'accueil' && (
       <> 
         <div className='developpement' id="accueil">
            <p className="competence">Développement web personnalisé pour une présence en ligne réussie</p>
          </div>
          
         
          <div className="portfolio reveal-2">
              <div className="developpement">
                <p className="teknik"></p>
              </div>
              <div className="background">
                
                <div className="circleBar">
                  <div
                    className="circular-progress1"
                    style={{
                      background: `conic-gradient(#EB5A23 ${progressStartValue1 * 3.6}deg, #ededed 0deg)`
                    }}
                  >
                    <span className="progress-value1">{progressStartValue1}%</span>
                  </div>
                  <span className="text">Lundi - vendredi : 9h / 18h</span>
                </div>
                <div className="circleBar">
                  <div
                    className="circular-progress1"
                    style={{
                      background: `conic-gradient(#EB5A23 ${progressStartValue2 * 3.6}deg, #ededed 0deg)`
                    }}
                  >
                    <span className="progress-value1">{progressStartValue2}%</span>
                  </div>
                  <span className="text">Open Source</span>
                </div>
                <div className="circleBar">
                  <div
                    className="circular-progress1"
                    style={{
                      background: `conic-gradient(#EB5A23 ${progressStartValue3 * 3.6}deg, #ededed 0deg)`
                    }}
                  >
                    <span className="progress-value1">{progressStartValue3}%</span>
                  </div>
                  <span className="text">Site Responsive</span>
                </div>
                <div className="circleBar">
                  <div
                    className="circular-progress1"
                    style={{
                      background: `conic-gradient(#EB5A23 ${progressStartValue4 * 3.6}deg, #ededed 0deg)`
                    }}
                  >
                    <span className="progress-value1">{progressStartValue4}%</span>
                  </div>
                  <span className="text">Espace client</span>
                </div>
              </div>
            </div>
          
            <div className="travail">
            <div className="process">Mes procédures de travail</div>
            <div className="case-cadre">
            <div className="case"> <img src={conception} alt="projet VBJ" className='procedure' /></div>
            <div className="skill">Conception de site</div>
            </div>
            <div className="case-cadre">
            <div className="case"> <img src={support} alt="projet Support" className='procedure' /></div>
            <div className="skill">Support & Maintenance</div>
            </div>
            <div className="case-cadre">
            <div className="case"> <img src={training} alt="projet Formation" className='procedure' /></div>
            <div className="skill">Formation</div>
            </div>
            </div>
            <p className="recherche">Si vous êtes à la recherche d'un développeur web compétent pour votre projet de développement back et front end JavaScript, vous êtes au bon endroit. En tant que développeur web indépendant en portage salarial, je suis en mesure de vous proposer des solutions innovantes et adaptées à vos besoins.</p>
            
            <div className="cadre-galerie">
              <p class="competence" id="works">Modèle de site</p>
              <div class="galerie">
                <div class="realisation" data-url="fashion.html">
                  <p class="website">Site vitrine : Envoie par mail de la commande</p>
                  <img src={fashionBoutique} alt="projet 1" className='fashionboutique' />
                  <p>Site vitrine dynamique : Système d'envoie de la commande par mail - mailto -, animation
                    javascript du carrousel, HTML 5 et CSS 3 et formulaire de contact.</p>
                </div>
                <div class="realisation" data-url="restaurant.html">
                  <p class="website">Site vitrine : Système de réservation</p>
                  <img src={restaurant} alt="projet 1" className='fashionboutique' />
                  <p>Site vitrine dynamique : Système de réservation et envoie de la commande par mail - mailto -, animation
                    javascript du carrousel, HTML 5 et CSS 3 et formulaire de contact.</p>
                </div>
              </div>
            </div></>

       
        )}


        {activeTab === 'travaux' && (
         <><p className="worky" id="works">Mes réalisations</p><div className="galerie" id="travaux">

          <div className="realisation">
            <p className="website">Site à destination des entreprises, particuliers, groupes etc. </p>
            <a href="https://www.shareexcellence.fr/" target="_blank" rel="noopener noreferrer">
              <img src={ShareExcellence} alt="Bdd" className='boutique' />
            </a>
            <p>- Node.js, express.js, sequelize, Mysql et nodemailer,
              - Mobile first et React 18
              ...
            </p>
            <p className="website">Optimisation d'un portfolio d'un photographe</p>
            <a href="https://mjandia.github.io/ninacarducci.github.io/" target="_blank" rel="noopener noreferrer">
              <img src={Nina} alt="Portfolio d'un photographe" className='boutique' />
            </a>
            <p>- Test Lighthouse,
              - Règles d'accessibilité,
              - SEO...
            </p>
          </div>
        </div></>
        )}

      {activeTab === 'blog' && (
  
         <><p> Cet article parlera de plusieurs choses mais avant tout de l'optimisation de site internet. Nous verrons donc comment améliorer la vitesse de chargement de votre site internet,
            le SEO pour un meilleur référencement et enfin comment améliorer l'expérience utilisateur. Mais on peut se demander l'utilité d'une telle démarche. En fait, vous pouvez avoir le meilleur site internet du monde,
            mais si celui-ci est lent, mal référencé et que l'expérience utilisateur est mauvaise, vous n'aurez pas de visiteurs. Et si vous n'avez pas de visiteurs, vous n'aurez pas de clients. C'est tout simple.
          </p><h3>L'Audit pour commencer</h3><p>
              Avant de commencer à optimiser votre site internet, il faut faire un audit. Cela permet de voir les points à améliorer et de voir les points forts de votre site internet.
              Pour cela, vous pouvez utiliser des outils comme "Google Lighthouse", "GTMetrix" ou encore "WebPageTest". Ces outils vous permettront de voir les points à améliorer.



              Premièrement, il faut savoir que la vitesse de chargement de votre site internet est un facteur important pour le référencement.
              Le critère est la crawl budget, c'est le temps que Google passe sur votre site internet pour le référencer.
              Vous pouvez voir le crawl budget dans la Google Search Console. Rendez-vous dans l'onglet "exploration" puis "statistiques de l'exploration".
              Vous avez 3 graphiques : le nombre de pages crawlées par jour, le nombre de kilo-octets crawlés par jour et le temps passé à crawler votre site internet.

              1. Nombre de pages crawlées par jour :
              Il s'agit du nombre de pages que Google a visité sur votre site internet, cette statistique doit être constante.
              Ici, on pourra voir également si Google a arrêté de crawler votre site internet.

              2. Nombre de kilo-octets crawlés par jour :
              Ici il s'agit pour Google d'indexer et de télécharger votre site donc de mesurer son poids en kilo-octets.
              Normalement plus votre site internet est léger plus il sera rapide à charger.
              Mais également plus il y aura de pages avec du contenu différent et plus Google aimera cela, par contre site sera plus lourd.

              3. Temps passé à crawler votre site internet :
              Il s'agit du temps que Google passe à crawler votre site internet, plus exactementn une page, plus ce temps est bas moins Google mets de temps à explorer votre site.
              Ce qui est bon.

              Donc, en résumé, moins Google meet de temps à crawler votre site internet, plus il pourra crawler de pages et
              plus il pourra crawler de pages plus il pourra indexer de pages et au final, moins il dépensera de ressources pour crawler votre site internet.
            </p><h3>Améliorer la vitesse de chargement de votre site</h3><div class="blog-1">


              <strong>1. Optimiser les images :</strong>
              Les images sont très importantes pour votre site internet, elles permettent d'illustrer vos articles, mais il ne faudrait pas que cellesci ralentissent votre site internet.
              En effet, une erreur courante est de mettre des images trop lourdes sur votre site internet. Par exemple, de mettre une image de grande taille et de l'adapter avec du CSS. Ou encore,
              de ne pas les optimiser, ainsi pirivlégier le "webp" au "png" ou au "jpeg". Compressez vos images avec des outils comme "tinypng" ou "compressor.io".

              <strong>2. Optimiser le code javascript :</strong>
              Minifier et compresser le code javascript permet de réduire la taille des fichiers javascript. Vous disposez d'outils comme "uglify" ou "minifier.org".
              Minifier signifie supprimer les espaces, les commentaires et les caractères inutiles et compresser signifie réduire la taille des variables et des noms de fonctions.

              <strong>3. Utiliser Gzip :</strong>
              Gzip est un algorithme de compression de données, il permet de réduire la taille des fichiers HTML, CSS et Javascript. Pour cela vous devez l'activer sur votre serveur.
              Pour l'activer, vous devez ajouter une ligne de code dans le fichier ".htaccess" de votre serveur. La réduction peut aller jusqu'à 70%.
              Cette ligne de code est la suivante : "AddOutputFilterByType DEFLATE text/html text/css text/javascript application/javascript application/x-javascript text/x-js text/x-javascript".

              <strong>4. Utiliser defer :</strong>
              L'attribut "defer" permet de charger les scripts javascript en arrière-plan. Ainsi, le navigateur n'attendra pas que le script soit chargé, ce qui peu-être bloquant, pour continuer à charger le reste de la page.

              <strong>5. Utiliser le cache pour augmenter la vitesse de votre site :</strong>
              Le cache permet de stocker les fichiers de votre site internet dans le navigateur de l'utilisateur. Ainsi, lorsque l'utilisateur reviendra sur votre site internet,
              le navigateur n'aura pas besoin de recharger les fichiers, ce qui permettra d'augmenter la vitesse de chargement de votre site internet. Pour gérer cela, vous pouvez utiliser le fichier ".htaccess" de votre serveur.
              Dans le fichier ".htaccess", vous devez ajouter les lignes de code suivantes :
              "ExpiresActive On", "ExpiresByType image/gif A2592000", "ExpiresByType image/png A2592000", "ExpiresByType image/jpg A2592000", "ExpiresByType image/jpeg A2592000".

              Enfin, il y a le cache-control qui permet de définir la durée de vie du cache. Pour cela, vous devez ajouter les lignes de code suivantes dans le fichier ".htaccess" de votre serveur :
              "Header set Cache-Control "max-age=2592000, public"".

              <strong>6. Pre Rendering pour une meilleur optimisation SEO :</strong>
              Le pre rendering permet de précharger les pages de votre site internet. Ainsi, lorsque l'utilisateur cliquera sur un lien, la page sera déjà chargée. 

              Il faut savoir que Google a beaucoup de mal a lire le javascript (que dire alors des autres moteurs de recherche, il n'y arrive quasiment pas), il est donc préférable d'utiliser le pre rendering pour les sites internet en javascript. Un site internet en HTML/CSS n'a pas besoin de pre rendering.
              Par contre, il est préférable d'utiliser le pre rendering pour les sites internet en javascript. Vous pouvez utiliser un site gratuit pour le pre rendering comme "prerender.io".

              Après, avoir vu comment techniquement optimiser votre site internet, voyons maintenant comment optimiser votre site internet pour le SEO.
            </div><h3>Optimiser votre site internet pour le SEO</h3><div class="blog-2">
              <p>
                <strong>Analyser le comportement de Google</strong>

                Pour optimiser votre site internet pour le SEO, il faut tout d'abord comprendre comment Google fonctionne. Pour cela, vous pouvez utiliser la "Google Search Console".
                Mais aussi votre navigateur afin d'anlyser les réponses Google lors d'une recherche. Il y a les liens sponsorisé, les résultats organiques et les résultats enrichis.
                Donc, bien comprendre la structure d'une page de réponse de Goole c'est savoir se positionner sur les bons mots clés par exemple.
                Pour un bon SEO on page (en effet, on dit on page, c'est relatif au site lui-même alors que le Off page est relatif aux liens externes), il faut que votre site internet soit bien structuré.

                <strong>La structure d'un site internet : titre, image et contenu</strong>

                La structure d'un site internet est très importante pour le SEO. Il faut que votre site internet soit bien structuré. Pour cela, vous devez utiliser les balises HTML suivantes :
                "h1" pour le titre principal, "h3" pour les sous-titres, "h3" pour les sous-sous-titres, "p" pour les paragraphes, "img" pour les images, "a" pour les liens, "strong" pour les mots importants, "em" pour les mots en italique, "ul" pour les listes à puces et "ol" pour les listes numérotées.


                <p class="baliseseo"><strong>Les balises meta</strong>
                </p>
              </p>
            </div><div class="blog-3">
              <p>
                Les balises meta sont très importantes pour le SEO.
                Il y a la balise "title" qui permet de définir le titre de votre page. Il y a la balise "description" qui permet de définir la description de votre page.
                Il y a la balise "keywords" qui permet de définir les mots clés de votre page. Il y a la balise "robots" qui permet de définir si votre page doit être indexée ou non. Il y a la balise "author" qui permet de définir l'auteur de votre page.
                Il y a la balise "viewport" qui permet de définir la largeur de votre page. Il y a la balise "charset" qui permet de définir l'encodage de votre page.
                Il y a la balise "canonical" qui permet de définir la page canonique de votre page. Il y a la balise "alternate" qui permet de définir la page alternative de votre page.
                Il y a la balise "refresh" qui permet de définir le temps de rafraichissement de votre page. Il y a la balise "http-equiv" qui permet de définir le type de contenu de votre page.
              </p>
            </div><p class="baliseseo"><strong>Les balises sémantiques</strong></p><div class="blog-4">
              <p>
                Les balises sémantiques sont très importantes pour le SEO.
                Il y a la balise "header" qui permet de définir l'en-tête de votre page. Il y a la balise "nav" qui permet de définir la navigation de votre page. La balise "main" qui permet de définir le contenu principal de votre page.
                Il y a la balise "article" qui permet de définir un article de votre page. Il y a la balise "section" qui permet de définir une section de votre page. Il y a la balise "aside" qui permet de définir un contenu secondaire de votre page.
                Il y a la balise "footer" qui permet de définir le pied de page de votre page. Il y a la balise "figure" qui permet de définir une figure de votre page. Il y a la balise "figcaption" qui permet de définir une légende de votre page.
              </p>
            </div><h3>Les KPI ou key performance indicator</h3><div class="blog-5">
              <p>
                Les KPI ou key performance indicator sont très importants pour le SEO. Ils permettent de mesurer la performance de votre site internet. On distingue :
                Le nombre de visiteurs : il permet de mesurer le nombre de visiteurs sur votre site internet.
                Le nombre de pages vues : il permet de mesurer le nombre de pages vues sur votre site internet.
                Le taux de rebond : il permet de mesurer le taux de rebond sur votre site internet.
                Le temps passé sur le site : il permet de mesurer le temps passé sur votre site internet.
                Le nombre de pages vues par visite : il permet de mesurer le nombre de pages vues par visite sur votre site internet.
                Le nombre de visiteurs uniques : il permet de mesurer le nombre de visiteurs uniques sur votre site internet.
                Le nombre de visiteurs récurrents : il permet de mesurer le nombre de visiteurs récurrents sur votre site internet.
                Le nombre de visiteurs par source : il permet de mesurer le nombre de visiteurs par source sur votre site internet.
                Le nombre de visiteurs par navigateur : il permet de mesurer le nombre de visiteurs par navigateur sur votre site internet.
                Le nombre de visiteurs par type d'appareil : il permet de mesurer le nombre de visiteurs par type d'appareil sur votre site internet.</p>
            </div><h3>Les outils SEO</h3><div class="blog-6">
              <p>
                Les outils SEO sont très importants pour le SEO. Ils permettent d'analyser votre site internet. On distingue :

                Google Analytics : Analytics permet de mesurer le nombre de visiteurs sur votre site internet.
                Google PageSpeed Insights : Insights permet de mesurer la vitesse de chargement de votre site internet.
                Google Mobile-Friendly Test : Mobile-Friendly Test permet de mesurer la compatibilité mobile de votre site internet.
                Google Structured Data Testing Tool : Structured Data Testing Tool permet de mesurer la structure de données de votre site internet.
                Google Trends : Trends permet de mesurer la tendance de votre site internet.
                Google Optimize : Optimize permet de mesurer l'optimisation de votre site internet.
                Google Keyword Planner : Keyword Planner permet de mesurer les mots-clés de votre site internet.
                Google Lighthouse : Lighthouse permet de mesurer la performance de votre site internet.
              </p>
            </div><h3>Les bonnes pratiques SEO</h3><div class="blog-7">
              <p>
                Les bonnes pratiques SEO sont très importantes pour le SEO. Elles permettent d'optimiser votre site internet. On distingue :
                Le choix des mots-clés : il permet de choisir les mots-clés de votre site internet. On dstingue parmi les mots-clés : les mots-clés de marque, les mots-clés généralistes,
                les mots-clés d'information (la recherche début par une question ou par le mot définition), d'intention
                (la recherhe commence par des verbes d'intention ou d'action : réserver,achetre ou encore trouver),
                et locaux (la recherce indique une volonté de se déplacer). On distingue aussi courte traîne de longue traîne. La courte traîne correspond au requête à gros volume chez Google,
                souvent associé aux mots-clés d'intentions ou de généralistes. La longue traîne elle se focalise sur l'activité de votre site et a un faible trafic par contre il y est plus facile de placer en première position.
                Le choix des titres : il permet de choisir les titres de votre site internet, donc on a dit h1 (un et un seul h1 par page), h3 (plusieurs h3) ... De plus, votre titre doit être pertinent et en relation avec votre activité et votre contenu. D'ailleurs, si possible,
                faites en sorte de retrouver un mot clé du titre dans vos paragraphes et balises alt de vos images si posible
                Le choix des descriptions : ils permttent de faire un lien entre votre titre et donc votre activité et le contenu. donc on doit pouvoir y
                retouver l'idée du titre et de votre activité et donc aussi un mot ou deux mots clés du titre.
                Mais ne pas répéter bêtement le mot clés ni ne cherchez pas à l'inclure automatiquement, cherchez plutôt un correspondance et faites en sorte que cela cohérent
                avec la description faites pour vos clients. Car vous faites votre site en d'abord pour vos clients puis en
                vue d'optimiser pour Google. Donc, le mot clé, en résumé, doit être en relation avec votre contenu
                L'accessbilité : il permet de rendre votre site accessible à tous. Il faut donc faire attention à la taille de la police, aux couleurs,
                aux contrastes, aux possibilités de commander à la voix,  aux clavier...
                Les données structurées : Permettent un contenu que Google peut lire et comprendre facilement. Ce format permet de fournir à Google des éléments précis sur le contenu d'une page.
                Le maillage interne : il permet de faire un lien entre vos pages. Ici, le but est regrouper les pages entre elles par thématique.
                Le netlinking : il permet de faire un lien entre votre site internet et d'autres sites internet. Ici, le but est de faire un lien entre votre site et d'autres sites de qualité.
                Autorité ou réfécement naturel : Ce sont ici les liens qui pointent vers votre site internet. Plus vous avez de liens qui pointent vers votre site internet, plus vous avez de chance d'être bien référencé.
                Mais attention, il faut que ces liens soient de qualité. Et donc éviter tout abus, à peine d'être pénalisé par Google.
              </p>
            </div></>
             
              )}       
       </main>
        <div className="competenceRealisation">Réalisations</div>
        <div className="realisations">
         <Carousel logos={logos} urls={urls} />
         </div>
         
         <Footer />
  
   </div>
        
    );
  }

export default Home;