import React from "react";

function Error () {


return (
    <div className="container">
      
    <header>
        </header>
    <main>
        <h1>404 page non trouvée</h1>
    </main>
      <footer>
        <div className='footer'>
        <p className='event'>Informations Légales

© 2023 Vidal bravo - Jandia Miguel - 50 avenue de la Liberté 94700 Maisons-Alfort - Lundi Vendredi 9h / 18h

06 04 14 24 55 - info@vidalbravo-jandia.fr</p>
        </div>
      </footer>
      </div>
);
}

export default Error;