import React, { useEffect, useRef } from 'react';
import '../styles.css';

function Carousel({ logos }) {
  const carouselRef = useRef(null);
    const urls = [
    'https://vidalbravo-jandia.fr/',
    'https://www.shareexcellence.fr/',
    // ...
  ];

  useEffect(() => {
    const carousel = carouselRef.current;
    carousel.style.animation = 'none';
    void carousel.offsetHeight;
    carousel.style.animation = '';

    const timer = setInterval(() => {
      if (carousel) {
        carousel.scrollLeft += 1;
      }
    }, 30);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="carousel-container">
    <div ref={carouselRef} className="carousel">
      {logos.map((logo, index) => (
        <div key={index} className="slide">
          <a href={urls[index]} target="_blank" rel="noopener noreferrer"> {/* ajouté */}
            <img src={logo} alt="logo" className="logo" />
          </a> {/* ajouté */}
        </div>
      ))}
      {logos.map((logo, index) => (
        <div key={index + logos.length} className="slide">
          <a href={urls[index]} target="_blank" rel="noopener noreferrer"> {/* ajouté */}
            <img src={logo} alt="logo" className="logo" />
          </a> {/* ajouté */}
        </div>
      ))}
    </div>
  </div>
  );
}

export default Carousel;
