import React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import Home from './Home';
import Login from './Login';
import Register from './Register'
import Client from './Client'
import Contact from './Contact'
import Contact1 from './Contact1'
import Admin from './Admin'
import AuthGuard from "./components/AuthGuard";
import Error from "./components/Error";


const router = createBrowserRouter([
{
  path: "/",
  element: <Home />
},
{
  path: "*",
  element: <Error />,
},
{
  path: "/login",
  element: <Login />
},
{
  path: "/contact",
  element: <Contact />
},
{
  path: "/register",
  element: <Register />
},
{
  // Utilisez AuthGuard pour protéger la route "Client"
  path: "/client",
  element: (
    <AuthGuard>
      <Client />
    </AuthGuard>
  ),
},
{
  // Utilisez AuthGuard pour protéger la route "Client"
  path: "/contact1",
  element: (
    <AuthGuard>
      <Contact1 />
    </AuthGuard>
  ),
},
{
  // Utilisez AuthGuard pour protéger la route "Client"
  path: "/admin",
  element: (
    <AuthGuard>
      <Admin/>
    </AuthGuard>
  ),
},
]);
ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
