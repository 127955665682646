import React, { useState } from "react";
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import { Link, useNavigate } from 'react-router-dom';
import logooriginal from './image/Logo-min.webp';
import Footer from './components/Footer';
import './Log.css';

function Register() {
  // États pour les données d'inscription
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState('accueil'); 

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch('/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      // Si l'inscription réussit, redirigez l'utilisateur vers la page de connexion
      navigate('/login');
    } catch (error) {
      console.error('Il y a eu un problème avec la requête fetch:', error);
      // Gérer l'affichage d'une erreur pour l'utilisateur
    }
  };

  const handleNavLinkClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  }
 
 return (
    <div className="container">
          
    <header>
    <img src={lighthouse} alt="Performance" className='performance' />
    <img src={lighthouseMobile} alt="Performance" className='performance1' />
    <Link to='/' className='link'>
      <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
      <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
      <h2>Hello et Bienvenue !</h2>
      </Link>
    

      <nav>
      <a href="#accueil" onClick={(e) => handleNavLinkClick(e, 'accueil')}>Accueil</a>
      <a href="#travaux" onClick={(e) => handleNavLinkClick(e, 'travaux')}>Mes travaux</a>
      <a href="/login">Se Connecter</a>
        <a href="#blog" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
    </header>

 
 <main>
  <h1>Inscription</h1>
       <form onSubmit={handleSubmit}>
         <label htmlFor="email">Email:</label>
         <input 
           type="email" 
           id="email" 
           value={email} 
           onChange={(e) => setEmail(e.target.value)} 
           required 
         />

         <label htmlFor="password">Mot de passe:</label>
         <input 
           type="password" 
           id="password" 
           value={password} 
           onChange={(e) => setPassword(e.target.value)} 
           required 
         />

         {/* Ajoutez d'autres champs ici si nécessaire */}

         <button type="submit">S'inscrire</button>
       </form>
        </main>
        <Footer />

</div>
     
 );
}

export default Register;