import React, { useState } from 'react';
import './Contact.css';
import { Link, useNavigate } from 'react-router-dom';
import logooriginal from './image/Logo-min.webp';
import lighthouse from './image/lighthouse.webp';
import lighthouseMobile from './image/lighthouseMobile.webp';
import Footer from './components/Footer';

function Contact() {
  const [activeTab, setActiveTab] = useState('accueil'); 
    const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [statusMessage, setStatusMessage] = useState('');

  const validateEmail = (email) => {
    const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const checkEmail = () => {
    if (formData.email === "") {
      setStatusMessage("Veuillez remplir l'email !");
      return false;
    } else if (!validateEmail(formData.email)) {
      setStatusMessage("Veuillez entrer un email valide !");
      return false;
    }
    setStatusMessage("");
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name === "") {
      setStatusMessage("Veuillez remplir le nom !");
      return;
    }
    if (formData.subject === "") {
      setStatusMessage("Veuillez mettre un objet !");
      return;
    }
    if (formData.message === "") {
      setStatusMessage("Veuillez mettre un message !");
      return;
    }
    if (!checkEmail()) {
      return;
    }

    fetch("/contact1", {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-type": "application/json; charset=UTF-8"
      }
    })
    .then(response => response.json())
    .then(json => {
      console.log(json);
      setStatusMessage("Email envoyé !");
    })
    .catch(err => {
      console.log(err);
      setStatusMessage("Une erreur est survenue lors de l'envoi de l'email.");
    });
  };
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/login');
  };
  const handleNavLinkClick = (e, tabId) => {
    e.preventDefault();
    setActiveTab(tabId);
  }
  return (
    <div className='container'>
         <header>
          <img src={lighthouse} alt="Performance" className='performance' />
          <img src={lighthouseMobile} alt="Performance" className='performance1' />
          <Link to='/' className='link'>
            <h1 className="title"><img src={logooriginal} alt="Bdd" className='logo' />Je suis Miguel, Développeur FullStack</h1>
            <p className="developpeur">Node.js Express.js Sequelize Mysql et Nodemailer et React 18</p>
            <h2>Hello et Bienvenue !</h2>
            </Link>
          
    
            <nav>
      <a href="#accueil" onClick={(e) => handleNavLinkClick(e, 'accueil')}>Accueil</a>
      <a href="#travaux" onClick={(e) => handleNavLinkClick(e, 'travaux')}>Mes travaux</a>
      <a href="/login">Se Connecter</a>
        <a href="#blog" onClick={() => setActiveTab('blog')}>Le Blog</a>
      </nav>
          </header>
        <main>
    <form id="form" onSubmit={handleSubmit}>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        placeholder="Votre nom"
      />
      <input
        type="email"
        id="emailUser"
        name="email"
        value={formData.email}
        onChange={handleChange}
        onBlur={checkEmail}
        placeholder="Votre email"
      />
      <input
        type="text"
        id="subject"
        name="subject"
        value={formData.subject}
        onChange={handleChange}
        placeholder="Sujet"
      />
      <textarea
        id="message"
        name="message"
        value={formData.message}
        onChange={handleChange}
        placeholder="Votre message"
      />
      <button type="submit">Envoyer</button>
      <div id="message1">{statusMessage}</div>
    </form>
    </main>
    <Footer />
</div>

     
 );
}

export default Contact;